import axios from 'axios'

import pagina from "@/class/pagina";

export class Turma {
  static async obtemTodos(ano, n, escola, segmento) {
    const routePg = pagina.routePg;
    return axios.get('/' + ano + '/turmas?page=' + n + '&escola=' + escola + '&segmento=' + segmento + routePg)
  }
  static async obtemTodosCoordenacao(servidor_id, tipo, ano, n, segmento) {
    const routePg = pagina.routePg;
    return axios.get('/turmas/' + servidor_id + '/tipo/' + tipo + '/' + ano + '?page=' + n + '&segmento=' + segmento + routePg)
  }
  static async obtemUm(id) {
    return axios.get('/turmas/' + id, {
      //cache
      headers: {
        'cache-control': 'max-age=3600',
      }
    })
  }
  static async obtemPorEscola(id) {
    return axios.get('/turmas/' + id + '/escola')
  }
  static async obtemPorEscolaAno(id, ano) {
    return axios.get('/' + ano + '/turmas/' + id + '/escola')
  }

  static async disciplinaNaTurma(turma_id) {
    return axios.get('turma/disciplina', { params: { turma_id } });
  }
  static async cadastrar(payload) {
    return axios.post('/turmas', payload)
  }
  static async editar(id, payload) {
    return axios.put('/turmas/' + id, payload)
  }
  static async addDisciplina(payload) {
    return axios.post('/turma/disciplina', payload)
  }
  //alterar matricula
  static async alterarDadaMatricula(payload) {
    return axios.post('/turma/alterar/data/matricula', payload)
  }
  static async turmasDisciplina(payload) {
    return axios.get('/turma/disciplina?turma_id=' + payload)
  }
  static async turmasAvancada(payload) {
    return axios.post('/turmas/avanc?ano=' + payload.ano + '&escola_id=' + payload.escola_id + '&segmento_id=' + payload.segmento_id + '&serie_id=' + payload.serie_id);
  }

  static async visualizarTurmaAluno(aluno_id) {
    return axios.get('/turmas-do-aluno/' + aluno_id);
  }
  static async visualizarDisciplinas(turma_id) {
    return axios.get('/disciplina?turma_id=' + turma_id);
  }
  static async professorDaTurma(turma_id) {
    return axios.get('turma/professor?turma_id=' + turma_id);
  }

  static async addProfessorTurma(payload) {
    return axios.post('turma/professor', payload);
  }

  static async EditarProfessorTurma(payload) {
    return axios.put('turma/professor', payload);
  }

  static async obtemNotasAlunos(turma_id) {
    return axios.get('/nota/alunos/turma/' + turma_id);
  }

  static async obtemTurmaDisciplina(id) {
    return axios.get('/turma/disciplina/' + id);
  }

  static async obtemTurmaProfessor(id) {
    return axios.get('/turma/professor/' + id);
  }

  static async obtemTurmaProfessorV2(payload) {
    const ano = sessionStorage.getItem("anoSelecionado");
    return axios.post('turma/professor/v2/' + ano, payload);
  }

  static async obtemTurmaCoordenadorV1(payload) {
    return axios.post('turma/coordenador/v1', payload);
  }
  static async buscarProfessoresDaTurma(turma_id) {
    return axios.get('buscarprofessor/turma/' + turma_id, {
      //cache
      headers: {
        'cache-control': 'max-age=3600',
      }
    });
  }
  static async obtemPresencaTudo(turma_id) {
    return axios.get('presenca/turma/' + turma_id + '/tudo', {
      //cache
      headers: {
        'cache-control': 'max-age=3600',
      }
    });
  }

  static async ativacaoDaTurma(payload) {
    return axios.post('turma/ativar', payload);
  }

  static async obtemAlunosTurma(turma_id) {
    return axios.get('/turma/alunos?turma_id=' + turma_id, {
      //cache
      headers: {
        'cache-control': 'max-age=3600',
      }
    });
  }
  static async verificarProfessoresDisciplina(turma_id) {
    return axios.post('turma/disciplinas/disciplinacomprofessor?turma_id=' + turma_id)
  }
  static async obterProfessorTurmasAno(payload) {
    return axios.get('/turmas/professor/' + payload.professor + '/' + payload.ano);
  }

  static async obtemTodasAsDisciplinasTurma(turma_id) {
    return axios.get('turma/visualizar/disciplinas/' + turma_id);
  }

  static async obtemProfessorDisciplinasEspecialista(seg, disc, escola_id) {
    return axios.get('turma/professor/ministra/disciplina/' + seg + '/' + disc + '/' + escola_id);
  }

  static async verificaNaDisciplinaProfessor(disc, turma) {
    return axios.get('turma/visu/disciplina/' + disc + '/' + turma, {
      //cache
      headers: {
        'cache-control': 'max-age=3600',
      }
    });
  }
  static async deletarProfessorDaDisciplina(payload) {
    return axios.delete('turma/professor?turma_id=' + payload.turma_id + '&turma_disciplina_id=' + payload.turma_disciplina_id + '&professor_id=' + payload.professor_id);
  }
  static async deletarDisciplinaDaTurma(payload) {
    return axios.delete('turma/disciplina?turma_id=' + payload.turma_id + '&disciplina_id=' + payload.disciplina_id);
  }

  static async aturma(payload) {
    return axios.post('turma/resp', payload);
  }

  static async aturmaConfi(payload) {
    return axios.post('turma/configuracao', payload);
  }

  static async ativacãoTotalTurma(payload) {
    return axios.post('turma/total/ativar', payload);
  }

  static async turmaVerTudo(id) {
    return axios.get('turma/visualizar/turma/' + id);
  }
  static async showEscolaSegmentoTurma(payload) {
    return axios.get('turmas/ano/' + payload.ano + '/escola/' + payload.escola_id + '/segmento/' + payload.segmento_id);
  }

  static async deletarTurmaAmbienteCoordenacao(id) {
    return axios.delete('turmas/ambientecoordenador/' + id);
  }
  static async deletarTurmaAmbienteGestao(id) {
    return axios.delete('turmas/ambientegestor/' + id);
  }

  static async pesqTurmaCompleto(item, escola, segmento) {
    let ano = sessionStorage.getItem("anoSelecionado");

    return axios.get('/turmas/pesquisa/' + item + '/' + escola + '/' + segmento + '/completa' + '/' + ano)
  }



  static async pesqTurmaPorSerieSegmento(payload) {
    let ano = sessionStorage.getItem("anoSelecionado");
    return axios.get('turmas/escola/' + payload.escola + '/serie/' + payload.serie + '/segmento/' + payload.segmento + '/ano/' + ano);
  } buscarDisciplinas
  static async obterTodasTurmasDoProfessor(turma) {
    return axios.get('turmas/professor/todas/' + turma.professor + '/' + turma.ano);
  }
  static async obterTodasDisciplinaDeUmProfessorEmUmaTurma(payload) {
    return axios.get('buscarprofessor/turma/disciplinas/' + payload.turma_id + '/' + payload.professor_id);
  }

  static async desativarTurma(turma_id) {
    const info = {
      turma_id: Number(turma_id)
    }
    return axios.post('turmas/desabilitar', info);
  }
  static async buscarDisciplinasBySegmento(segmento_id, ano) {
    return axios.get('disciplinas/por/segmento?segmento_id=' + segmento_id + '&ano=' + ano);
  }
}
